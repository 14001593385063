<template>
    <div class="cfa-root">
      <c-f-a-nav :alert="alertString" contentClass="position-relative">
        <template v-slot:title>
          <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
            <h1 class="cfa-section-title">ACTIVITÉ</h1>
             <div class="dropdown-demo">
             <Dropdown v-model="selectedoption"  @change="getFeaturePlans()"  :options="cities"  placeholder="Sélectionnez la limite" class="w-full"  style="height: 30px; width:200px; margin-left:20px;"  />
            </div>
             
          </div>
        </template>
        <template v-slot:content>
          <div class="cfa-page ">
  <div class="card">
        <Timeline :value="plans" class="w-full md:w-80">
            <template #content="slotProps">
            <div class="timeline-card">
               <h5 style="color: #008080;"> {{ slotProps.item.model_name }}</h5>
               <p style="color:#808080;">{{ slotProps.item.description }}</p>
               </div>
            </template>
        </Timeline>

       
    </div>
            <br>
          </div>
        </template>
      </c-f-a-nav>
      <c-f-a-footer></c-f-a-footer>
  
      
  
      <!-- delete -->
     
  
      
    </div>
  </template>
  
  <script>
  import CFAFooter from '@/components/nav/CFAFooter.vue'
  import CFANav from '@/components/nav/CFANav.vue'
  //import Select from 'primevue/select'
import  Dropdown  from "primevue/dropdown";
  //import MSelectInput from '@/components/shared/input/MSelectInput.vue'

import Timeline from 'primevue/timeline';

  import {getAPI} from "@/axios-api";
  
  
  export default {
    name: 'PlanView',
    components: {
      CFAFooter,
    Timeline,
      CFANav,
     Dropdown,
     //Select
     
      //MSelectInput,
    },
    data () {
      return {
        alertString: null,
        newPlan: false,
        selectedoption: null,
            cities: [10,15,20,25,30,35],
         timelinetimelineEvents: [
        { title: "Event 1", date: "2023-12-01", description: "Description 1", color: "blue", icon: "mdi-star" },
        { title: "Event 2", date: "2024-01-15", description: "Description 2", color: "green", icon: "mdi-heart" },
        { title: "Event 3", date: "2024-03-20", description: "Description 3", color: "red", icon: "mdi-check" },
      ],
    
        plans: [],
        activityColumns: ['n°', 'Nom',  'Created At',  'Autres'],
        activityToListen: ['Autres'],
  
        openDeletionBox: null,
        tempDeleteID: null,
        tempDeleteName: '',
  
        waitingAPIResponse: true,
        waitingAPIResponse2: true,
        waitingAPIResponse3: true,
        selectedPlan: null,
  
        exitAddPlanModal: null,
        options: ['list', 'of', 'options'],
  
        plan_name: '',
        plan_price: '',
        plan_duration: '',
        plan_type: '',
        plan_modules: [],
        plan_modules_update: [],
        feature_plan:[],
        plan_code: '',
        type_plan: '',
        credit_plan: '',
        user_nbres: '',
        name:'',
        description:'',
        permissions:'',
        callbackPayload: null,
        exitRoleModalDel: null,
        plan_deletion_check: null,
        openUpdatePerm: false,
        exitOrgUpdateModal: null,
  
        plan_smshing_credit: null,
        plan_phishing_credit: null,
        plan_features: [],
        plan_price_month: null,
        plan_price_year: null
      }
    },
    methods: {
      getPlans () {
        this.waitingAPIResponse = true
        getAPI.get(`audit-logs/all/`)
            .then(response => {
                console.log(response);
              if(response.status == 200) {
                this.plans = response.data.results;
                //this.plans = []
                
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse = false
              }, 2000);
            })
            .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse = false
            })
      },
      getFeaturePlans () {
        
      this.waitingAPIResponse = true
      getAPI.get(`audit-logs/all/?limit=${this.selectedoption}`)
          .then(response => {
            if(response.status == 200) {
              console.log(this.selectedoption.name);
             this.plans = response.data.results;
              console.log(response.data);
              
            }
           
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            
            this.waitingAPIResponse = false
          })
    },
      getCallToAction1 (payload) {
        console.log(payload)
        if(payload.action_type == 'delete') {
          this.openDeletionBox = true;
          this.tempDeleteID = payload.id;
          this.tempDeleteName = payload.name;
        }
        if(payload.action_type == 'view') {
          this.updateData(payload.id);
        }
      },
      updateData (id) {
        this.tempUpdateRoleId = id
        this.waitingAPIResponse2 = true
        this.openUpdatePerm = true
        getAPI.get(`activity/${id}/`)
            .then(response => {
              if(response.status == 200) {
                this.selectedPlan = response.data;
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              setTimeout(() => {
                this.waitingAPIResponse2 = false
              }, 2000);
            })
            .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.waitingAPIResponse2 = false
            })
      },
      getSelectedModules (payload) {
        console.log(payload)
        this.plan_modules = [];
        for (let i = 0; i < payload.length; i++) {
          this.plan_modules.push(payload[i].id)
          console.log(payload[i])
          console.log(this.plan_modules)
        }
      },
      getSelectedModulesUpdate (payload) {
        console.log(payload)
        this.plan_modules_update = [];
        for (let i = 0; i < payload.length; i++) {
          this.plan_modules_update.push(payload[i].id)
        }
        this.selectedPlan.modules = this.plan_modules_update
      },
      addNewPlan () {
        // this.feature_plan.push(this.permissions)
        getAPI.post('activity/', {
          //code: "prod_P1rmN8wbFpVE69",
          name: this.name,
        // 
          
          // name_en: this.plan_name,
          // price: this.plan_price,
          // duration: this.plan_duration,
          // type_plan: this.plan_type,
          // modules: this.plan_modules
        })
        .then(response => {
          
          if(response.status == 201) {
            this.getPlans()
            let msg = 'activity ajouté avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitAddPlanModal = Date.now()
        })
        .catch(error => {
          
          if (error.response) {
            let firstError = Object.values(error.response.data)[0][0]
            this.alertString = `${firstError}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitAddPlanModal = Date.now()
        })
      },
      confirmDeletion () {
        if(!this.plan_deletion_check) {
          this.callbackPayload = 'Veuillez confirmer cette action.' + '::-1::' + Date.now();
        }
        else{
          getAPI.delete(`activity/${this.tempDeleteID}`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = ' activity supprimé avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getPlans()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitRoleModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
        }
      },
      confirmUpdate () {
        getAPI.patch(`activity/${this.tempUpdateRoleId}/`,{ name:this.selectedPlan.name}).then(response => {
          if(response.status == 200) {
            let msg = ' `activity MAJ avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgUpdateModal = Date.now()
            this.getPlans()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal = Date.now()
        })
            .catch(error => {
              if (error.response) {
                this.alertString = `This plan is already in use !|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgUpdateModal = Date.now()
            })
      },
    },
    mounted() {
      this.getPlans()
      this.getFeaturePlans()
    }
  }
  </script>
  
  <style scoped>
  .cfa-welcome{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  }
  .cfa-certif-state-line:hover{
      /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
    -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
    -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  }
  .cfa-progress-status{
    min-width: 7em;
  }
  .item {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.25em;
  }
  .timeline-item {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
}

.timeline-item:nth-child(odd) {
  animation-delay: 0.2s;
}

.timeline-item:nth-child(even) {
  animation-delay: 0.4s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.container {
  
}

.card {
  display: flex;
  justify-content: flex-start; /* Aligns items to the left */
  //padding: 20px;
  width: 600px;
  //background-color: #f8f9fa;
  //border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* Adjusting the timeline card */
.timeline-card {
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensures the card fits within the timeline item */
  max-width: 300px; /* Optional: Set a max width */
  text-align: left; /* Align content to the left */
}

/* Optional: Style timeline content for better appearance */
.p-timeline-event-content {
  display: flex;
  justify-content: flex-start; /* Align the card to the left */
}

.p-timeline-event-opposite {
  margin-right: 10px; /* Add spacing between event marker and card */
}

.p-timeline-event {
  align-items: flex-start; /* Ensure the marker aligns with the top of the card */
}


  </style>
  