<template>
    <div class="cfa-root d-flex align-items-center justify-content-center cfa-bg-secondary oauth-wrp" style="height: 100vh; background-color: #f7f8fc;">
      <form @submit.prevent="handleSubmit" class="auth-form">
        <div class="auth-form-header">
          <a href="/">
            <div class="auth-form-header-illustration mx-auto"></div>
          </a>
          <span class="admtag">admin</span>
          <div class="auth-form-header-separator"></div>
          <span class="auth-form-header-title">Connexion</span>
        </div>
  
        <div v-if="!showOtpForm" class="auth-form-content">
          <div class="mt-3">
            <modern-input @inputChange="(e) => { connect_email = e }" placeholder="Votre Adresse email" errorText="Champ requis" uid="cfa-username-bloc" type="email"></modern-input>
          </div>
          <div class="mt-5">
            <modern-input @inputChange="(e) => { connect_pass = e }" placeholder="Votre Mot de passe" errorText="Champ requis" uid="cfa-password-bloc" type="password"></modern-input>
          </div>
          <div class="auth-form-link mt-3 text-center">
            <a href="/password-forget" class="forgot-password">Mot de passe oublié ?</a>
          </div>
  
          <div class="auth-form-actions">
            <button type="submit" class="oauth-button">
              <span v-if="!waitingAPIResponse">Je me connecte</span>
              <img v-else src="@/assets/img/gif/load2.gif" alt="loading gif" style="width: 30px;"/>
            </button>
          </div>
  
          
  
          
        </div>
        <div v-else class="auth-form-content">
        <div class="mt-3">
          <modern-input @inputChange="(e) => { otpCode = e }" placeholder="Entrez OTP" errorText="Champ requis" uid="cfa-otp-bloc" type="text"></modern-input>
        </div>
        <div class="auth-form-actions">
          <button type="submit" class="oauth-button">
            <span >vérifier OTP</span>
            <!-- <img v-else src="@/assets/img/gif/load2.gif" alt="loading gif" style="width: 30px;"/> -->
          </button>
        </div>
      </div>
      </form>
  
      <transition name="fadeY" appear mode="out-in">
        <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
      </transition>
    </div>
  </template>
  
  <script>
  import ModernInput from '@/components/shared/input/ModernInput.vue'
  import Alert from '@/components/shared/alert/Alert.vue'
  import axios from "axios";
//   import { googleSignIn } from '@/services/googleAuth'
  
  export default {
    name: 'LoginView',
    components: {
      ModernInput,
      Alert
    },
    data() {
      return {
        connect_email: '',
        connect_pass: '',
        alertMe: false,
        gAlertMessage: '',
        gAlertType: '',
        waitingAPIResponse: false,
        showOtpForm: false,
       otpCode: '',
      }
    },
    methods: {
      handleSubmit() {
      if (this.showOtpForm) {
        this.verifyOtp()
      } else {
        this.tryLogin()
      }
    },
      displayError(error, type = 'alert-ok') {
        this.gAlertMessage = error
        this.gAlertType = type
        this.alertMe = true
        setTimeout(() => {
          this.alertMe = false
        }, 5000);
      },
      tryLogin() {
        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.connect_email) || this.connect_pass.length < 2) {
          this.displayError('Oups ! Veuillez remplir les champs convenablement.', 'alert-no')
        } else {
          this.waitingAPIResponse = true
          axios.post(`${process.env.VUE_APP_BASE_URL}accounts/admin/login`,
            {
              email: this.connect_email,
              password: this.connect_pass,
            })
            .then(response => {
              if (response.status == 200) {
                  this.showOtpForm = true;
                // const token = response.data.data.access
                // const refresh_token = response.data.data.refresh
                // const user_data = response.data.data.user
                // const msg = response.data.message
                // sessionStorage.setItem('token', token)
                // sessionStorage.setItem('refresh_token', refresh_token)
                // sessionStorage.setItem('user', user_data.id)
                // sessionStorage.setItem('firstname', user_data.firstname)
                // sessionStorage.setItem('lastname', user_data.lastname)
                // sessionStorage.setItem('image', null)
  
                // this.$emit('connected', token)
                // this.displayError(msg, 'alert-yes')
                // setTimeout(() => {
                //   this.waitingAPIResponse = false
                //   let a = document.createElement('a');
                //   a.href = "/management-board"
                //   a.click()
                //   a.remove()
                // }, 700);
              } else {
                const msg = 'Oups ! something went wrong';
                this.displayError(msg, 'alert-no')
                this.waitingAPIResponse = false
              }
            })
            .catch(error => {
              // sessionStorage.removeItem('token')
              if (error.response) {
                if (error.response.data.msg == "Invalid Credentials") {
                  this.displayError("Adresse email ou mot de passe incorrect(e)", 'alert-no', 10000)
                } else {
                  const msg = 'Invalid Credentials';
                  this.displayError(msg, 'alert-no', 10000)
                }
              } else if (error.request) {
                this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
              } else {
                this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
              }
              this.waitingAPIResponse = false
            })
        }
      },
      verifyOtp() {
        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.connect_email)) {
          this.displayError('Oups ! Veuillez remplir les champs convenablement.', 'alert-no')
        } else {
          this.waitingAPIResponse = true
          axios.post(`${process.env.VUE_APP_BASE_URL}accounts/unlock-login`,
            {
              email: this.connect_email,
              code: this.otpCode,
            })
            .then(response => {
              if (response.status == 200) {
                const redirectPath = sessionStorage.getItem('redirectPath');
             if (redirectPath) {
               sessionStorage.removeItem('redirectPath');
               this.$router.push(redirectPath); // Redirect to the saved path
             } else {
                this.$router.push('/'); // Default path
               }
                  this.showOtpForm = true;
                const token = response.data.data.access
                const refresh_token = response.data.data.refresh
                const user_data = response.data.data.user
                const msg = response.data.message
                sessionStorage.setItem('token', token)
                sessionStorage.setItem('refresh_token', refresh_token)
                sessionStorage.setItem('user', user_data.id)
                sessionStorage.setItem('firstname', user_data.firstname)
                sessionStorage.setItem('lastname', user_data.lastname)
                sessionStorage.setItem('image', null)
  
                this.$emit('connected', token)
                this.displayError(msg, 'alert-yes')
                setTimeout(() => {
                  this.waitingAPIResponse = false
                  let a = document.createElement('a');
                  a.href = "/management-board"
                  a.click()
                  a.remove()
                }, 700);
              } else {
                const msg = 'Oups ! something went wrong';
                this.displayError(msg, 'alert-no')
                this.waitingAPIResponse = false
              }
            })
            .catch(error => {
              sessionStorage.removeItem('token')
              if (error.response) {
                if (error.response.data.msg == "Invalid Credentials") {
                  this.displayError("Adresse email ou mot de passe incorrect(e)", 'alert-no', 10000)
                } else {
                  const msg = 'Invalid Credentials';
                  this.displayError(msg, 'alert-no', 10000)
                }
              } else if (error.request) {
                this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
              } else {
                this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
              }
              this.waitingAPIResponse = false
            })
        }
      },
      async signInWithGoogle() {
        // try {
        //   const user = await googleSignIn()
        //   console.log('Google sign-in successful:', user)
        //   // Implement your logic to authenticate the user with your backend
        // } catch (error) {
        //   console.error('Google sign-in error:', error)
        //   this.displayError('La connexion avec Google a échoué. Veuillez réessayer.', 'alert-no')
        // }
      },
    },
    mounted() {
      if (this.$route.query.loginRequired && this.$route.query.loginRequired == "true") {
        this.displayError("Bienvenu ! Vous devez vous connecter pour accéder à ce contenu.", "alert-middle", 6500);
      }
    }
  }
  </script>
  
  <style scoped>
  .auth-form {
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 400px;
  }
  
  .auth-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
  }
  
  .auth-form-header-illustration {
    width: 181px;
    height: 40px;
    background-image: url(@/assets/brand/spec-brand-1.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 20px;
  }
  
  .admtag {
    padding: 2px 8px;
    background-color: #06bbc4;
    color: #fefeff;
    border-radius: 6px;
    font-size: 13px;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  
  .auth-form-header-separator {
    width: 200px;
    height: 1px;
    background-color: #bfcee1;
    margin: 20px 0;
  }
  
  .auth-form-header-title {
    color: #476388;
    font-size: 24px;
    font-weight: 600;
  }
  
  .auth-form-content {
    width: 100%;
  }
  
  .auth-form-link {
    margin-top: 20px;
  }
  
  .forgot-password {
    color: #476388;
    text-decoration: underline;
    font-size: 14px;
  }
  
  .auth-form-actions {
    margin-top: 30px;
  }
  
  .oauth-button {
    background-color: #06bbc4;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 12px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .oauth-button:hover {
    background-color: #059aa1;
  }
  
  .auth-form-separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 30px 0;
  }
  
  .auth-form-separator::before,
  .auth-form-separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #bfcee1;
  }
  
  .auth-form-separator span {
    padding: 0 10px;
    color: #476388;
    font-size: 14px;
  }
  
  .google-signin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #bfcee1;
    border-radius: 25px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .google-signin-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .google-signin-button i {
    font-size: 20px;
    margin-right: 10px;
    color: #4285F4; /* Google blue */
  }
  
  .google-signin-button span {
    color: #476388;
    font-size: 16px;
    font-weight: 600;
  }
  
  @media only screen and (max-width: 600px) {
    .auth-form {
      border-radius: 0;
      box-shadow: none;
      padding: 20px;
    }
  }
  </style>
